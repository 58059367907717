
import './App.css';
import React from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

import Thankyou from './Thankyou'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryScreen() {
  let query = useQuery();
  return (
  
        <Thankyou id={query.get("id")} />
     
  )
}
function App() {
 
  return (
    <Router>
      <Switch>
        <QueryScreen />
      </Switch>
    </Router>
  );
}

export default App;
