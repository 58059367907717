import React, { useState, useEffect } from "react";
import axios from "axios";
export default function Thanyou(props) {
  
    const [confirmation, setConfirmation] = useState(1)
    const [ok, setOk] = useState(false)
    const [token, setToken] = useState('nZpNAAxMPgpyHZrhEhJyEbHTyebJVLMv')
    const [datos, setDatos] = useState({
        nombre: '',
    })
    const [delay, setDelay] = useState(0)
 
    console.log(props.id)
    const getData = async () => {
        const response = await axios.post(`https://europe-west1-flexicar-backend.cloudfunctions.net/digital_signature/api/v1/getConfirmationPageInfo`, {
            id: props.id
        },
            {
                headers: {
                    'token': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .catch(() => setConfirmation(3))
        if (response) {
            if (response.data.data.confirmationStateId === 1) {
                setConfirmation(response.data.data.confirmationStateId)
                setDelay(delay+1)
                if (delay <= 5) {
                    setOk(!ok)
                }
                
                  
            }
            else if (response.data.data.confirmationStateId !== 1) {
                setConfirmation(response.data.data.confirmationStateId)
                setDatos({ nombre: response.data.data.nombre })
            }
            console.log(response)
        }
    }

  
    useEffect(() => {
        console.log(confirmation)
        let interval2
        if (confirmation === 1) {
            
            interval2 = setTimeout(getData, 2000);
        }
        if (confirmation === 2) {
            clearTimeout(interval2)
        }
        if (confirmation === 3) {
            clearTimeout(interval2)
        }
    }, [ok]);
    

    return (
        <>
            {!confirmation || confirmation === 1 & delay <= 5 ?
                <div className="spinner">
                    <h1>Procesando su solicitud</h1>
                    <div id="loading"></div>
                </div>
                :
                <div className="App">
                    <div className="gap"></div>
                    <div className="bg">
                        <div className="content">

                            <a href="https://www.flexicar.es"><img className="logo" src="https://www.flexicar.es/media/imagenes/logotipoflexicarajustadoplantilla.png" /></a>
                            <div className="bar"></div>
                            <a href="https://www.flexicar.es"><img className="hero" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/mail%20crm%20copia.gif" /></a>
                            <div className="content-box">
                                {confirmation === 1 && delay === 5 ?
                                    <>
                                        <h1 className="flexicar-color">Ha ocurrido un error con la firma de su contrato</h1>
                                        <p className="text">Vuelva a intentarlo más tarde
	            </p>
                                    </>
                                    : null}
                                {confirmation === 2 ?
                                    <>
                                        <h1 className="flexicar-color">Muchas gracias {datos.nombre}</h1>
                                        <p className="text">En breve recibirá un email con su contrato firmado
	            </p>
                                        <p className="text">¡Saludos!</p>
                                    </>
                                    : null}



                            </div>

                            <div className="orange-band">
                                <p><a className="url" href="https://www.flexicar.es">www.flexicar.es</a></p>
                            </div>
                            <div className="social-icons">
                                <a href="https://www.facebook.com/flexicar.es/"><img className="social-btn" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/FACEBOOK.png" /></a>
                                <a href="https://www.instagram.com/flexicar.es/"><img className="social-btn" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/INSTAGRAM.png" /></a>

                                <a href="https://twitter.com/Flexicar_es"><img className="social-btn" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/Twitter.png" /></a>
                                <a href="https://www.youtube.com/channel/UCfV2YY0IFtIHDnk3oNvhKVg"><img className="social-btn" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/YOUTUBE.png" /></a>
                                <a href="https://www.linkedin.com/company/grupo-flexicar"><img className="social-btn" src="https://storage.googleapis.com/gvo_imagenes/images/generic/email_templates/LINKEDIN.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

